import React, { Component } from 'react';

import NavBar from '../../Components/NavBar/NavBar'

import FileSaver from 'file-saver'
let userId = undefined;

export class Download extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			loaded: false,
		};
	}

	componentDidMount() {
		userId = this.props.match.params.userId
		this.props.db.collection('users').doc(userId).get()
		    .then((res) => {
		    		console.log(res.data())
					this.setState({
						data: res.data(),
						loaded: true,
					})
				})
		this.props.storage.ref('videos').child(userId+".mp4").getDownloadURL()
			.then(url => {
				
					this.setState({
						video: url,
						videoLoaded: true,
					})
				
			})
	}

	


	render() {

		const handleShare = (e) => {
		fetch("https://zlivideos.videyou.ch/videos/"+userId+".mp4")
		  .then(function(response) {
		    return response.blob()
		    console.log(response.blob())
		  })
		  .then(function(blob) {

		    var file = new File([blob], "video.mp4", {type: 'video/mp4'});
		    var filesArray = [file];

		    if(navigator.canShare && navigator.canShare({ files: filesArray })) {
		      navigator.share({
		        files: filesArray,
		      })
		      .then(() => alert('Successful share'))
                .catch((error) => alert('Error sharing'+ error));
		    } else {
		    	/*alert('no')*/
		    }
		  })
		  /*.catch((error) => alert('Blob error'+ error))*/
		}

	
		let video = (<p>Du hast noch kein Video erstellt oder das Video ist noch nicht fertiggestellt, <a href={"/create/"+userId}>Create</a>mit Link auf Create Page</p>)
		if (this.state.video !== undefined) {
			video = (<video src={this.state.video} controls width="280px"/>)
		}

		const handleDownload = (e) => {
			FileSaver.saveAs("https://zlivideos.videyou.ch/videos/"+userId+".mp4", "abschlussvideos_zli.mp4")
		}
		return (
			<div className="Container">
				<NavBar />
				<h1>Dein Video ist bereit zum teilen!</h1>
				{video}
				<br />
				<button onClick={(e) => handleDownload(e)}>Video herunterladen</button>
				{/*<a download="zli-abschlussvideo.mp4" href="https://zlivideos.videyou.ch/videos/xjS6fGcgZUeUWiFHWR52.mp4">Download</a>
				<a download href={"instagram://"+this.state.video}>Instagram</a>
				<a href={"https://www.facebook.com/sharer.php?u="+this.state.video+"&t=ZLI&20%Abschlussvideo"}>Facebook</a>*/}
			</div>
		);
	}
}

export default Download