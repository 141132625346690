import React, { Component } from 'react';

import logo from '../../assets/logo.png'

import styles from './NavBar.module.css';

export class NavBar extends Component {
	/*constructor(props) {
	}

	componentDidMount() {
	}
*/
	render() {
		return (
			<div className={styles.container}>
				<img src={logo} alt="Logo" className={styles.logo}/>
			</div>
		);
	}
}

export default NavBar