import React, { Component } from 'react';
import Loader from '../../assets/ball-triangle.svg'

import NavBar from '../../Components/NavBar/NavBar'
//import Dropzone from '../../Components/Dropzone/Dropzone'
let userId = undefined;

export class Create extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			loaded: false,
			image: null,
			triggeredVideoCreation: false	,
		};

	}
	componentDidMount() {
		userId = this.props.match.params.userId
		this.props.db.collection('users').doc(userId).get()
		    .then((res) => {
		    		//console.log(res.data())
					this.setState({
						data: res.data(),
						loaded: true,
					})
				})
		this.props.storage.ref('images').child(userId+"_3840x5000.jpeg").getDownloadURL()
			.then(url => {
				this.setState({
					image: url,
					imageLoaded: true,
				})
			})
	}

	render() {
		const handleChange = e => {
			if (e.target.files[0]) {
				console.log('changed')
				this.setState({
					uploadImage: e.target.files[0]
				})
			}

		}

		const handleUpload = e => {
			if (e.target.files[0]) {
				console.log('changed')
				this.setState({
					uploadImage: e.target.files[0]
				})
			}
			this.setState({
				imageUploaded: false
			})
			const uploadTask = this.props.storage.ref('images/'+userId+".jpg").put(e.target.files[0]);
			uploadTask.on(
				"state_changed",
				snapshot => {},
				error => {
					console.log(error);
				},
				() => this.props.storage.ref('images').child(userId+".jpg").getDownloadURL()
					.then(url => {
						console.log(url)
						this.setState({
							image: url,
							imageLoaded: true,
							imageUploaded: true,
						})
					})
				)
			
		}

		const handleImageReset = () => {
			this.setState({
				imageUploaded: undefined,
				imageLoaded: false,
				uploadImage: undefined
			})
		}

		const handleCreateVideo = () => {
			this.setState({triggeredVideoCreation: true})
			const res = this.props.db.collection('jobs').add({
				userId: userId,
				status: 'created'
			});
					console.log(res)
		}


		let imageUpload = (
			<div className="ImageUpload">
				<input type="file" onChange={handleUpload} accept="image/png, image/jpeg, image/tiff, image/webp" /><br />
			</div>
		)
		{/*if (this.state.uploadImage === undefined) {
			let imageUpload = (
				<div className="ImageUpload">
					<input type="file" onChange={handleUpload} accept="image/png, image/jpeg, image/tiff, image/webp" /><br />
				</div>
			)
		}*/}

		if (this.state.uploadImage !== undefined) {
			imageUpload = (
				<div className="ImageUpload">
					<img alt="" width="300px" src={URL.createObjectURL(this.state.uploadImage)} /> <br />
					<button onClick={handleUpload}>Dieses Bild verwenden</button> &nbsp;&nbsp;&nbsp;
					<button className="secondary" onClick={handleImageReset}>Anderes Bild verwenden</button>&nbsp;&nbsp;&nbsp;
				</div>
			)
		}
		if (this.state.imageUploaded === false) {
			imageUpload = <img alt="" src={Loader} className="UploadLoader"/>
		}
		if (this.state.imageUploaded === true) {
			imageUpload = (
				<div className="ImageUpload">
					<img width="300px" src={this.state.image} alt="noch kein Bild"/><br />
					<button className="secondary" onClick={handleImageReset}>Anderes Bild verwenden</button>&nbsp;&nbsp;&nbsp;
					<button onClick={handleCreateVideo}>Video erstellen</button>
				</div>
			)
		}

		let view = <p>Loading</p>;
		if (this.state.loaded === true && this.state.triggeredVideoCreation === false) {
			view = (
				<div>
					<h1>Erstelle dein persönliches Abschluss-Video!</h1>
					<h2>So geht's:</h2>
					<h4 className="num">1.</h4>
					<p className="step">Wähle ein Foto aus und lade es hoch</p><br />
					<h4 className="num">2.</h4>
					<p className="step">Klicke auf «Video erstellen»</p><br />
					<h4 className="num">3.</h4>
					<p className="step">Wir melden uns, sobald Du<br />	 dein Video teilen kannst.</p><br/>
					
					{/*<p>Vorname: {this.state.data.vorname}</p> 
					<p>Nachname: {this.state.data.nachname}</p>
					<p>Beruf: {this.state.data.beruf}</p>
					<p>Betrieb: {this.state.data.betrieb}</p>
					<p>Lehrjahre: {this.state.data.lehrjahre}</p>*/}
					
					{imageUpload}
					
				</div>
				)
		} else if (this.state.triggeredVideoCreation === true) {
			view = (
				<div>
					<div className="tick-animation-ctn">
  						<div className="icon icon--order-success svg">
         					<svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
            					<g fill="none" stroke="#8EC343" stroke-width="2"> 
              						<polyline className="st0" stroke="orange" stroke-width="8" points="43.5,77.8 63.7,97.9 112.2,49.4 "/>   
            					</g> 
          					</svg>
        				</div>
					</div>

					<h4 className="successText">Dein Video wird nun erstellt! 
					<br />Das kann ein paar Minuten dauern. Wir schicken Dir eine E-Mail sobald dein persönliches Video bereit ist.</h4>
				</div>
				)
		}

		return (
			<div className="Container">
				<NavBar />
				{view}
			</div>
		);
	}
}

export default Create