import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";

import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Create from './Container/Create/Create'
import Download from './Container/Download/Download'

import './App.css';


function App() {

      // Initialize Cloud Firestore through Firebase
   let config = ({

      apiKey: "AIzaSyAEBjkrKZjWNVD4RwvWUjq5Ft4-aPkzidw",
      authDomain: "nexrender-testing.firebaseapp.com",
      projectId: "nexrender-testing",
      storageBucket: "nexrender-usercontent",
      messagingSenderId: "253551051925",
      appId: "1:253551051925:web:10498152c17c311cd3079c",
      measurementId: "G-EP61H0X749"
    });
   
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  firebase.analytics();
  const db = firebase.firestore()
  const storage = firebase.storage()


  return (
    <Router>
        <div className="App">
            <Switch>
              <Route path='/create/:userId' render={(props) => <Create {...props} db={db} storage={storage}/>}/>
              <Route path='/download/:userId' render={(props) => <Download {...props} db={db} storage={storage}/>}/>
            </Switch>
        </div>
      </Router>
  );
}

export default App;
